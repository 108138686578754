
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import HotelCatalogService, { HotelCatalogServiceS } from '../hotel-catalog.service';

@Component({
    components: { StyledSearchbar },
})
export default class HotelCatalogSearch extends Vue {
    @inject(HotelCatalogServiceS) private hotelCatalogService!: HotelCatalogService;

    query: string = '';

    get isUrl() {
        return this.hotelCatalogService.isUrl(this.query);
    }

    get isDomainAllowed() {
        if (!this.isUrl) { return false; }
        let { query } = this;

        if (!query.startsWith('http')) {
            query = `https://${query}`;
        }

        const allowedOrigins = ['booking', 'expedia'];
        const url = new URL(query);
        const [domain] = url.hostname
            .replace('www.', '')
            .split('.');

        return allowedOrigins.includes(domain);
    }

    submit() {
        if (!this.query) {
            return;
        }

        if (this.isUrl && !this.isDomainAllowed) {
            this.$emit('error', 'This domain is not supported in URL search');
            return;
        }

        this.$emit('submit', this.query);

        this.hotelCatalogService.update(this.query);
    }
}
