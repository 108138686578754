
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import AddCompetitorsTab from '../components/add-competitors.tab.vue';
import AddHotelsTab from '../components/add-hotels.tab.vue';

@Component({
    components: {
        PageWrapper,
        TabsController,
        AddHotelsTab,
        AddCompetitorsTab,
    },
})
export default class OnBoardingPage extends Vue {
    @inject(CompsetsServiceS)
    compsetsService!: CompsetsService;

    stepsComponents = [AddHotelsTab, AddCompetitorsTab];
    step = 0;

    isValidStep = false;
    isPending = false;

    get isAbleToNext() {
        return this.step !== this.stepsComponents.length - 1 && !this.isPending;
    }

    get isFirstStep() {
        return !this.step;
    }

    nextStep() {
        if (!this.isAbleToNext) {
            this.save();
            return;
        }

        this.step += 1;
    }

    prevStep() {
        if (this.step > 0) {
            this.step -= 1;
        }
    }

    save() {
        this.isPending = true;
        this.compsetsService
            .createCompset()
            .then(() => {
                window.location.href = '/';
            })
            .finally(() => {
                this.isPending = false;
            });
    }
}
