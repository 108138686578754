
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import MAP_STYLES from '@/modules/common/constants/map-styles.constant';
import gmapsInit from '@/modules/common/services/map.service';

// from docs https://developers.google.com/maps/documentation/javascript/using-typescript#module_import
// eslint-disable-next-line import/no-unresolved
import {} from 'googlemaps';

declare global {
    interface Window {
        initMap: Function;
    }
}

export interface MarkerData {
    position: { lat: number, lng: number },
    icon: string,
    tooltip: string,
    zIndex: number,
}
@Component({})
export default class GoogleMap extends Vue {
    @Prop({
        required: false,
        type: Array as () => MarkerData[],
    })
    private markersData!: MarkerData[];

    @Prop({
        required: false,
        type: Number,
        default: 2,
    })
    private zoom!: number;

    @Prop({
        required: true,
        type: Object as () => {lat: number, lng: number},
    })
    private center!: { lat: number, lng: number };

    @Prop({
        required: false,
        default: false,
    })
    private zoomControls!: boolean;

    map!: google.maps.Map;
    infoWindow!: google.maps.InfoWindow;
    markers!: google.maps.Marker[];
    isBlocked = false;

    async mounted() {
        try {
            await gmapsInit();
            if (this.$refs.googleMap) {
                this.initMap();
            }
            this.$emit('ready');
        } catch (err) {
            this.isBlocked = true;
            // eslint-disable-next-line
            console.error(err);
        }
    }

    // eslint-disable-next-line consistent-return
    initMap() {
        this.map = new google.maps.Map(this.$refs.googleMap as HTMLElement, {
            center: this.center,
            zoom: this.zoom,
            disableDefaultUI: true,
            styles: MAP_STYLES,
            zoomControl: this.zoomControls,
        });
        this.setMarkers();
    }

    clearOverlays() {
        if (!this.markers) {
            this.markers = [];
            return;
        }
        for (let i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.markers.length = 0;
    }
    @Watch('center')
    setCenter() {
        if (this.map) {
            this.map.setCenter(this.center);
        }
    }
    @Watch('markersData')
    setMarkers() {
        if (!this.map) {
            return;
        }
        this.clearOverlays();
        if (this.markersData.length > 0) {
            this.markersData.forEach(item => {
                if (!item.position) {
                    return;
                }
                if (!item.position.lat || !item.position.lng) {
                    return;
                }
                const marker = new google.maps.Marker({
                    position: item.position,
                    map: this.map,
                    icon: item.icon,
                    zIndex: item.zIndex,
                });
                this.addTooltip(marker, item);
                this.markers.push(marker);
            });
        }
    }

    addTooltip(marker: google.maps.Marker, item: MarkerData) {
        if (!item.tooltip) {
            return;
        }
        marker.addListener('click', () => {
            if (this.infoWindow) {
                this.infoWindow.close();
            }
            this.infoWindow = new google.maps.InfoWindow({
                content: item.tooltip,
            });
            this.infoWindow.open(this.map, marker);
            this.map.setCenter(item.position);
        });
    }

    calculateDistance<T extends { lat: number; lng: number }>(from: T, to: T) {
        const fromLatLng = new google.maps.LatLng(from.lat, from.lng);
        const toLatLng = new google.maps.LatLng(to.lat, to.lng);
        return google.maps.geometry.spherical.computeDistanceBetween(fromLatLng, toLatLng);
    }
}
